<template>
  <div class="p-5 [ bg-white shadow-custom-2 rounded-xl ]">
    <h2 class="text-xl text-primary-A900 font-bold">{{ $t('title') }}</h2>
    <p class="mt-2 text-sm text-secondary-700">{{ $t('description') }}</p>

    <form class="flex flex-col gap-y-8" @submit="submit">
      <TextInput
        id="nationalId"
        name="nationalId"
        class="w-full text-base"
        :label="$t('nationalId')"
        border
        rounded
        required
      />

      <FileInput
        id="nationalIdImage"
        name="nationalIdImage"
        accept="image/*"
        :label="$t('nationalIdImage')"
        :inner-text="$t('uploadNationalIdImage')"
      />

      <AppButton
        class="flex items-center justify-center w-full text-lg mt-6 px-3 py-3 relative z-1 [ font-bold md:w-1/2 ]"
        variant="default"
        :loading="isSubmitting"
      >
        {{ $t('verify') }}
      </AppButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import * as yup from 'yup';
const { t: $t } = useI18n({
  useScope: 'local',
});
const { setCustomerNationalId, nationalId, nationalIdFront } = useSetCustomerNationalId();

const { handleSubmit, isSubmitting, setValues } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      nationalId: yup
        .string()
        .required()
        .matches(/^\d{14}$/, 'National ID must be 14 numbers'),
      nationalIdImage: yup
        .mixed<File>()
        .test('required', 'NationalID required', value => {
          return value && value.size > 0;
        })
        .test('fileSize', 'File too large', value => {
          return value && value.size <= 1000000;
        })
        .test('fileType', 'Unsupported File Format', value => {
          return value && ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type);
        }),
    }),
  ),
});

const submit = handleSubmit(async ({ nationalId, nationalIdImage }) => {
  try {
    const image = await extractBase64WithoutHeader(nationalIdImage as File);
    const id = await setCustomerNationalId(nationalId, image);

    if (!id) {
      throw new Error('Invalid ID');
    }
  } catch (e) {
    console.log(e);
  }
});

watch([nationalId, nationalIdFront], async ([nationalId, nationalIdFront]) => {
  if (nationalId && nationalIdFront) {
    console.log('nationalId', nationalId);
    const file = await urlToFile(nationalIdFront, 'nationalId.jpg');
    setValues({
      nationalId,
      nationalIdImage: file,
    });
  }
});
</script>

<i18n>
{
  "en": {
    "title": "ETA Validation",
    "description": "Based on ETA (Egyptian Tax Authority), you need to submit your national ID number and national ID image to complete your order.",
    "nationalId": "National ID",
    "nationalIdImage": "National ID Image",
    "uploadNationalIdImage": "Upload National ID Image",
    "verify": "Verify"
  },
  "ar": {
    "title": "تحقق من الهوية",
    "description": "بناءً على الهيئة العامة للضرائب المصرية، يجب عليك تقديم رقم الهوية الوطنية وصورة الهوية الوطنية لإكمال طلبك.",
    "nationalId": "الهوية الوطنية",
    "nationalIdImage": "صورة الهوية الوطنية",
    "uploadNationalIdImage": "تحميل صورة الهوية الوطنية",
    "verify": "التحقق"
  }
}
</i18n>
