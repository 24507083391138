import { useMutation, useQuery } from 'villus';
import * as yup from 'yup';
import type { ShippingMethodInput } from '~/graphql-types.gen';
import {
  IsNationalIdVerifiedDocument,
  TotalThresholdDocument,
  UpdateCustomerNationalIdDocument,
} from '~/graphql/NationalId';

export function useCheckoutPaymentValidationSchema() {
  const validationSchema = computed(() => {
    return toTypedSchema(
      yup.object({
        paymentMethod: yup.object().required().default(null),
        shippingMethod: yup.mixed<ShippingMethodInput>().required().default(null),
      }),
    );
  });

  return {
    validationSchema,
  };
}

/**
 * @description based on ETA ( Egyptian Tax Authority ) rules, need to verify the payment national id before proceeding with the payment, only if the order amount is greater than a configured value
 *
 * */
const etaAccepted = ref(false);
export function useValidatePaymentNationalId() {
  const { total } = useCartAttributes();
  const { data: verifiedNationalIdData, isFetching: isFetchingNationalId } = useQuery({
    query: IsNationalIdVerifiedDocument.toString(),
    cachePolicy: 'network-only',
  });

  const {
    data,
    error,
    isFetching: isFetchingTotalThreshold,
  } = useQuery({
    query: TotalThresholdDocument.toString(),
    cachePolicy: 'network-only',
  });

  if (error.value) {
    console.error(error.value);
  }

  watch(data, () => {
    if (etaAccepted.value) return;

    if (data.value) {
      const totalThreshold = data.value?.storeConfig?.grandTotalThreshold;
      etaAccepted.value = totalThreshold ? totalThreshold > total.value : false;
    }
  });

  watch(verifiedNationalIdData, () => {
    if (verifiedNationalIdData.value) {
      etaAccepted.value = !!verifiedNationalIdData.value?.customer?.national_id?.national_id;
    }
  });
  const requireEta = computed(() => {
    if (!data.value?.storeConfig?.grandTotalThreshold) return false;

    return data.value?.storeConfig?.grandTotalThreshold <= total.value;
  });

  const isFetching = computed(() => {
    return isFetchingTotalThreshold.value || isFetchingNationalId.value;
  });
  return {
    etaAccepted,
    requireEta,
    isFetching,
  };
}

export function useSetCustomerNationalId() {
  const { execute } = useMutation(UpdateCustomerNationalIdDocument.toString());
  const { data } = useQuery({
    query: IsNationalIdVerifiedDocument.toString(),
  });

  const setCustomerNationalId = async (nationalId: string, frontImage: string) => {
    try {
      const { data, error } = await execute({
        input: {
          national_id: nationalId,
          national_id_front: frontImage,
        },
      });

      if (error) {
        throw error;
      }

      if (!data?.updateCustomerNationalId?.customer.national_id) {
        throw new Error('No data returned');
      }
      etaAccepted.value = true;
      return data.updateCustomerNationalId.customer.national_id;
    } catch (error) {
      console.error(error);
    }
  };

  const nationalId = computed(() => {
    return data.value?.customer?.national_id?.national_id;
  });

  const nationalIdFront = computed(() => {
    return data.value?.customer?.national_id?.national_id_front;
  });

  return {
    setCustomerNationalId,
    nationalId,
    nationalIdFront,
  };
}
