<template>
  <div>
    <form @submit="onSubmit">
      <Coupons
        :applied-coupons="appliedCoupon"
        :loading="loading"
        class="block"
        :code="values.code"
        @submit="() => onSubmit()"
        @remove="() => removeCouponOnCart()"
      />
    </form>
  </div>
</template>
<script setup lang="ts">
import { object, string } from 'yup';

defineComponent({
  name: 'CheckoutCoupons',
});

const { appliedCoupon: cartCoupons } = useCartAttributes();
const { isFetching: isApplyingPromoCode, applyCoupon } = useApplyPromocode();
const { isFetching: isRemovingPromoCode, removeCode } = useRemovePromocode();

const { error, success } = useAlerts();
const { t } = useI18n();

const { t: $t } = useI18n({
  useScope: 'local',
});
const { handleSubmit, setErrors, resetForm, values } = useForm({
  validationSchema: toTypedSchema(
    object({
      code: string().required(),
    }),
  ),
});

const onSubmit = handleSubmit(async ({ code }) => {
  try {
    await applyCoupon(code);
    success(t('cartSuccess').toString(), t('couponAdded').toString());
    resetForm();
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());

    setErrors({ code: $t((e as Error).message).toString() });
  }
});

async function removeCouponOnCart() {
  try {
    await removeCode();
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());
  }
}

const appliedCoupon = computed(() => (cartCoupons.value ? [cartCoupons.value] : []));

const loading = computed(() => isApplyingPromoCode.value || isRemovingPromoCode.value);
</script>
<i18n>
{
  "en": {
    "haveAPromoCode": "Have A Promo code ?",
    "cancel": "Cancel",
    "invalidCoupons": "Invalid Promo Code"
  },
  "ar": {
    "haveAPromoCode": "لديك برومو كود ؟",
    "cancel": "الغاء",
    "invalidCoupons": "كود خاطئ"
  }
}
</i18n>
