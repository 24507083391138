<template>
  <div class="Checkout__container relative mt-8">
    <div slim data-cy-region="checkout-payment">
      <Spinner v-if="isPageLoading" class="w-6 h-6" />
      <form v-else @submit="onSubmit">
        <CheckoutEtaValidationFragment v-if="requireEta" class="mb-10" />

        <div class="px-5 mx:px-0 flex items-center justify-between">
          <h3 class="text-primary-A900 text-xl font-bold [ flex items-center ] gap-x-5">
            <svg-icon name="package" width="40" height="30" />
            {{ $t('shippingInformation') }}
          </h3>
        </div>

        <dl class="mt-8 text-sm grid grid-cols-1 px-5 lg:px-0">
          <div class="flex items-center justify-between">
            <dt class="text-primary-A900">{{ $t('shippingMethod') }}</dt>
            <AppLink
              v-if="!isGuest"
              to="/checkout/shipping"
              class="uppercase flex items-center gap-x-3 underline text-primary-800 text-base"
            >
              <svg-icon name="edit" class="w-5 h-5 ml-2" />
              <span class="underline"> {{ $t('edit') }} </span>
            </AppLink>
          </div>

          <template v-if="address">
            <dd class="mt-5 text-gray-500 flex flex-col gap-y-5">
              <div class="flex items-center gap-x-20">
                <span>{{ address.firstname }} {{ address.lastname }}</span>
                <span>{{ address.telephone }}</span>
              </div>
              <Address :value="address" />
            </dd>
          </template>
        </dl>

        <aside v-if="!isStorePickup && !shippingMethods?.length" class="block my-10 text-red-700 text-lg">
          {{ $t('noShippingMethods') }}
        </aside>

        <fieldset v-if="!isStorePickup && shippingMethods" class="mt-8 px-5 lg:px-0 flex flex-col">
          <div class="relative mt-3">
            <div
              v-if="isUpdatingShippingMethod"
              class="[ absolute top-0 left-0 ] flex items-center justify-center w-full h-full"
            >
              <Spinner class="w-8 h-8" />
            </div>
            <RadioInput
              v-for="shippingMethod in shippingMethods"
              :id="`shippingMethod_${shippingMethod.carrier_code}`"
              :key="shippingMethod.carrier_code"
              name="shippingMethod"
              track-by="carrier_code"
              class="first:mt-0 mt-4"
              :class="{ 'opacity-50 pointer-events-none': loading }"
              :value="shippingMethod"
              :disabled="!shippingMethod.isActive"
              reversed
            >
              <template #labelDescription>
                <div class="flex text-sm items-center text-gray-500">
                  <span v-if="shippingMethod.maxDays && shippingMethod.minDays" class="mr-2">
                    {{
                      $t('shippingMethods.deliverWithin', {
                        minDays: shippingMethod.minDays,
                        maxDays: shippingMethod.maxDays,
                      })
                    }}
                  </span>

                  <span v-if="shippingMethod.cutoffTime" class="mr-2">
                    {{ $t('shippingMethods.forOrders', { cutoffTime: shippingMethod.cutoffTime }) }}
                  </span>

                  <span class="flex items-center mr-2"> (+ <Money :value="shippingMethod.amount.value" />) </span>
                </div>
              </template>

              <template #default>
                <div class="flex flex-col text-primary-A900 text-lg">
                  {{ shippingMethod.carrier_title }}

                  <span v-if="shippingMethod.minSubTotalFreeShipping" class="font-normal text-gray-500 items-center">
                    {{ t('shippingMethods.freeOver') }}
                    <Money :value="Number(shippingMethod.minSubTotalFreeShipping)" class="mx-2" />
                  </span>
                </div>
              </template>
            </RadioInput>
          </div>
        </fieldset>

        <h2 class="mt-8 px-5 lg:px-0 text-xl text-primary-A900 font-bold font-body [ flex items-center ]">
          <svg-icon name="wallet" width="43" height="38" class="mr-2" />
          {{ $t('payment') }}
        </h2>

        <fieldset class="px-5 lg:px-0 mt-16 flex flex-col">
          <legend class="text-primary-A900 font-bold">{{ $t('selectPaymentMethod') }}</legend>

          <div class="mt-7 relative">
            <div
              v-if="isUpdatingPaymentState"
              class="[ absolute top-0 left-0 ] flex items-center justify-center w-full h-full"
            >
              <Spinner class="w-8 h-8" />
            </div>
            <PaymentStrategyDirector
              v-if="paymentMethods"
              :class="{ 'opacity-50 pointer-events-none': loading }"
              :payment-methods="paymentMethods"
            />
          </div>
        </fieldset>

        <div class="px-5 md:px-0">
          <CheckoutCoupons class="mt-18" />
        </div>

        <div class="mt-8 px-5 md:px-0 hidden md:flex">
          <AppButton
            type="submit"
            class="mt-2 w-full md:w-1/2 flex justify-center"
            :disabled="blockPayment"
            :loading="isPlacingOrder || isSubmitting || loading || isFetching || isUpdatingShippingMethod"
            inverted
            @click="onSubmit"
          >
            <span class="text-center font-bold text-lg"> {{ $t('placeOrder') }}</span>
          </AppButton>
        </div>
      </form>
    </div>

    <div class="mt-16 md:mt-0 md:space-y-5 flex flex-col self-start md:sticky md:top-0 px-5 md:px-0">
      <div class="flex flex-col items-center justify-center">
        <CheckoutPaymentSummary class="w-full" :disable-shipping-fees="true">
          <template #extensions>
            <p v-if="shippingFees || shippingFees === 0" class="mt-2 flex items-center text-base px-8">
              <span class="text-base text-secondary-700">{{ $t('shippingFees') }}</span>
              <Money class="ml-auto text-base" :value="shippingFees" fallback="Free" />
            </p>
          </template>
        </CheckoutPaymentSummary>
        <div class="md:hidden w-full px-5 md:px-0">
          <AppButton
            type="button"
            class="flex md:hidden mt-13 w-full md:w-1/2 justify-center"
            :disabled="blockPayment"
            :loading="isPlacingOrder || isSubmitting || loading || isFetching || isUpdatingShippingMethod"
            inverted
            @click="onSubmit()"
          >
            <SvgIconCartCheck width="24" height="20" class="mr-4" />
            <span class="text-center uppercase"> {{ $t('placeOrder') }}</span>
          </AppButton>
        </div>
      </div>

      <CheckoutCartSummary class="mt-13 md:mt-0 pb-16 lg:pb-24" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AvailablePaymentMethod, ShippingMethod } from '@robustastudio/e-commerce/common';

const { isFetchingCart, address, isStorePickup, shippingFees } = useCartAttributes();
const { isPageLoading } = useSsrCoreApp([isFetchingCart]);
const { etaAccepted } = useValidatePaymentNationalId();
const { requireEta, isFetching } = useValidatePaymentNationalId();

const route = useRoute();
const isGuest = computed(() => route.params?.source?.toString() === 'guest');

definePageMeta({ layout: 'checkout' });

const { t: $t } = useI18n({ useScope: 'local' });
const { t } = useI18n({
  useScope: 'global',
});

const { validationSchema } = useCheckoutPaymentValidationSchema();

const { handleSubmit, errors, isSubmitting, meta, values, setValues } = useForm({
  validationSchema,
});

const onSubmit = handleSubmit(async ({ paymentMethod, shippingMethod }) => {
  try {
    await submitCheckout(
      ref(paymentMethod) as unknown as Ref<AvailablePaymentMethod>,
      ref(shippingMethod) as unknown as Ref<ShippingMethod>,
    );
  } catch (e) {
    console.log(e);
  }
});

const {
  loading,
  submitCheckout,
  isPlacingOrder,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  paymentMethods,
  shippingMethods,
  isInvalidOptions,
  isUpdatingShippingMethod,
  isUpdatingPaymentState,
  resetErrors,
  setShippingMethod,
} = useCheckout(
  computed(() => ({
    withIframeEnabled: false,
    cached: 'cache-first',
    withIframeQueryParam: false,
  })),
  ['checkmo'],
);

watch(
  () => errors,
  newErrors => {
    if (
      isInvalidOptions.value &&
      newErrors.value &&
      Object.keys(newErrors.value).length &&
      Object.keys(newErrors.value)?.every((errorKey: string) => (newErrors.value as any)[errorKey] === 0)
    ) {
      resetErrors();
    }
  },
);

const unwatch = watch(address, address => {
  if (address?.selected_shipping_method) {
    setValues({
      shippingMethod: {
        carrier_code: address.selected_shipping_method.carrier_code,
        method_code: address.selected_shipping_method.method_code,
      },
    });

    unwatch();
  }
});

watch(
  () => values.shippingMethod,
  newVal => {
    if (newVal) {
      setShippingMethod({
        carrier_code: newVal?.carrier_code || 'N/A',
        method_code: newVal?.method_code || 'N/A',
      });
    }
  },
);

useAppSeoMeta({
  title: 'Payment Information',
  description: 'Checkout by filling your payment information and paying for your order',
});

/**
 * Disable payment button when :
 *  - form is invalid
 *  - coupons modal is open
 *  - ETA is not accepted
 */
const blockPayment = computed(() => !meta.value.valid || (requireEta.value && !etaAccepted.value));
</script>

<i18n>
{
  "en":{
    "placeOrder": "Place Order",
    "backToShipping": "Back To Shipping",
    "storePickup": "Store Pickup",
    "shippingInformation": "Shipping Information",
    "homeDelivery": "Home Delivery",
    "edit": "Edit Shipping",
    "shippingMethod": "Shipping Method",
    "shippingAddress": "Shipping Address",
    "deliveryType": "Delivery Type",
    "selectDeliveryType": "Select Delivery Type",
    "selectPaymentMethod": "Select Payment Method",
    "phone": "Phone",
    "name": "Name",
    "address": "Address",
    "payment": "Payment Information",
    "free": "Free",
    "shippingFees": "Shipping Fees",
    "noShippingMethods": "* No shipping methods available, please contact the store"
  },
  "ar": {
    "placeOrder": "بدء الطلب",
    "backToShipping": "العوده للتوصيل",
    "storePickup": "الاستلام من الفرع",
    "shippingInformation": "معلومات التوصيل",
    "homeDelivery": "التوصيل المنزلى",
    "edit": "تعديل",
    "shippingMethod": "طريقه التوصيل",
    "shippingAddress": "عنوان التوصيل",
    "deliveryType": "نوع التوصيل",
    "selectDeliveryType": "اختار طريقه التوصيل",
    "selectPaymentMethod": "اختار طريقه الدفع",
    "phone": "التلفون",
    "name": "الاسم",
    "address": "العنوان",
    "payment": "الدفع",
    "free": "مجانا",
    "shippingFees": "رسوم التوصيل",
    "noShippingMethods": "لا توجد طرق توصيل متاحه, الرجاء التواصل مع المتجر"
  }
}
</i18n>
