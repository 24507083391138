<template>
  <div>
    <fieldset class="flex flex-col">
      <legend class="flex items-center gap-x-3 text-primary-A900 mb-8">
        <svg-icon name="coupon" width="30" height="30" />
        <span class="font-bold">{{ $t('AddPromoCode') }}</span>
      </legend>

      <TextInput
        v-if="!(single && appliedCoupons.length)"
        id="code"
        name="code"
        :label="$t('enter promo code')"
        type="text"
        class="text-primary-A900 relative"
        autocomplete="code"
        size="lg"
        :is-optional="false"
        rounded
        filled
        border
      >
        <template #after>
          <button
            type="submit"
            class="z-[1] bg-coloration-secondary rounded-2xl h-13 w-13 md:h-15 md:w-15 [ flex items-center justify-center ] [ absolute right-0 top-0 bottom-0 ]"
            :disabled="loading"
            inverted
          >
            <Spinner v-if="loading" class="text-white w-6 h-6 mx-auto" />
            <template v-else>
              <svg-icon
                name="arrow-left"
                width="18"
                height="15"
                class="text-white"
                :class="{
                  'transform rotate-180': $i18n.locale === 'en',
                }"
              />
            </template>
          </button>
        </template>
      </TextInput>
    </fieldset>
    <template v-if="appliedCoupons && appliedCoupons.length">
      <div v-for="(coupon, index) in appliedCoupons" :key="index" class="flex justify-between items-center">
        <div class="text-primary-A900 font-bold [ flex items-center justify-start gap-x-2 ]">
          {{ $t('promoCodeApplied') }}

          <div class="bg-green-200 font-normal text-[#76B357] py-1 px-4 rounded-xl [ flex items-center gap-x-2 ]">
            {{ coupon }}

            <svg-icon name="check" width="14" height="11"></svg-icon>
          </div>
        </div>

        <Spinner v-if="loading" class="w-6 h-6 ml-auto text-red-700" />

        <button
          v-else
          aria-label="clear coupon"
          type="button"
          class="ml-auto hidden md:flex [ text-red-700 items-center justify-center gap-x-2 ]"
          @click="$emit('remove', coupon || '')"
        >
          <svg-icon name="trash" width="20" height="20" class="text-red-700" />
          <span class="underline">
            {{ $t('deleteCode') }}
          </span>
        </button>
      </div>
    </template>

    <div v-if="!appliedCoupons.length" class="mt-4 flex items-center">
      <slot name="cancel" />
    </div>
  </div>
</template>
<script setup lang="ts">
defineComponent({
  name: 'Coupons',
});
defineProps({
  appliedCoupons: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  single: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  code: {
    type: String,
    default: '',
  },
});

const { t: $t } = useI18n({
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "code": "Code",
    "applyCoupon": "Apply PromoCode",
    "promoCodeApplied": "Code Applied:",
    "enter promo code": "Enter promo code",
    "AddPromoCode": "Add Promo Code",
    "deleteCode": "Delete Code"
  },
  "ar": {
    "code": "الكود",
    "applyCoupon": "تطبيق الرمز الترويجي",
    "promoCodeApplied": "تم تطبيق الرمز الترويجي",
    "enter promo code":  "أدخل الكود",
    "AddPromoCode": "أضف كود ترويجي",
    "deleteCode": "حذف الكود"
  }
}
</i18n>
