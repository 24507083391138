<template>
  <ul class="methods-list space-y-3" data-cy="payment-methods">
    <li v-for="paymentMethod in paymentMethods" :key="paymentMethod?.code">
      <RadioInput
        :id="`paymentMethod_${paymentMethod?.code}`"
        label=""
        name="paymentMethod"
        track-by="code"
        :value="paymentMethod"
      >
        <div class="ml-2 flex items-center justify-between w-full">
          <span v-if="paymentMethod" class="text-primary-A900 font-body w-full">{{ $t(paymentMethod.code) }}</span>
          <div
            v-if="paymentMethod?.code === 'robusta_mpgs_cc'"
            class="flex gap-2 lg:gap-5 items-center text-primary-500 ml-auto"
          >
            <svg-icon-master_card class="w-7 h-4 object-contain lg:w-10 lg:h-10" />
            <svg-icon-visa class="w-9 h-3 object-contain lg:w-12 lg:h-12" />
          </div>
          <img
            v-if="paymentMethod && resolveMethodIcon(paymentMethod)"
            :src="resolveMethodIcon(paymentMethod) ? resolveMethodIcon(paymentMethod) : '/payment/credit-card.svg'"
            height="45"
            width="45"
            class="ml-auto"
          />
        </div>

        <template #after>
          <PaymentStrategy
            v-if="
              selectedMappedMethod &&
              paymentMethod?.code === selectedMappedMethod.code &&
              selectedMappedMethod.component
            "
            :payment-component="selectedMappedMethod.component"
            class="mt-5"
          />
        </template>
      </RadioInput>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { AvailablePaymentMethod } from '@robustastudio/e-commerce/common';
type PaymentMethod = Omit<AvailablePaymentMethod, 'payment_fee' | 'is_deferred' | 'description'> | null | undefined;

const { t: $t } = useI18n({ useScope: 'local' });

function resolveMethodIcon(paymentMethod: PaymentMethod): string {
  const mapIcons: Record<string, string> = {
    cashondelivery: '/payment/cash-on-delivery.svg',
    robusta_accept_cc: '/payment/credit-card.svg',
    cardondelivery: '/payment/credit-on-delivery.svg',
    robusta_payfort_cc: '/payment/visa.svg',
  };

  return mapIcons[paymentMethod?.code ?? 'cashondelivery'];
}

const props = defineProps({
  paymentMethods: {
    type: Array as PropType<Array<PaymentMethod>>,
    default: () => [],
  },
});

const { value: selectedPaymentMethod } = useField<AvailablePaymentMethod>('paymentMethod');

const paymentComponentMethodMapper: Record<
  string,
  {
    component: string;
    code: string;
  }
> = {
  robusta_accept_cc: {
    component: 'Paymob',
    code: 'robusta_accept_cc',
  },
  robusta_accept_mobile_wallets: {
    component: 'Wallet',
    code: 'robusta_accept_mobile_wallets;',
  },
  robusta_payfort_cc: {
    component: 'Payfort',
    code: 'robusta_payfort_cc',
  },
};
function mapPaymentOption(apiItem: NonNullable<PaymentMethod>) {
  return {
    code: apiItem.code,
    title: apiItem.title,
    // TODO to be localized.
    icon: `/logos/payment/${apiItem.code}.png`,
    component: paymentComponentMethodMapper[apiItem.code]?.component || null,
  };
}

const selectedMappedMethod = computed(
  () =>
    selectedPaymentMethod.value &&
    props.paymentMethods
      .filter((method: any): method is AvailablePaymentMethod => method !== null)
      .map(method => mapPaymentOption(method))
      .find(method => method.code === selectedPaymentMethod.value?.code),
);
</script>
<style lang="postcss" scoped>
.methods-list li:not(:last-child) {
  @apply flex flex-col;
  &::after {
    @apply bg-gray-100 my-6;
    content: '';
    width: 100%;
    height: 1px;
  }
}
</style>

<i18n>
  {
    "en": {
      "robusta_accept_cc": "Credit Card",
      "robusta_accept_mobile_wallets": "Mobile Wallets",
      "robusta_payfort_cc": "Credit / Debit Card",
      "cashondelivery": "Cash on Delivery",
      "cardondelivery": "Credit on Delivery"
    },
    "ar": {
      "robusta_accept_cc": "بطاقة الائتمان",
      "robusta_accept_mobile_wallets": "محافظ الهاتف المحمول",
      "robusta_payfort_cc": "بطاقة الائتمان / الخصم",
      "cashondelivery": "الدفع عند الاستلام",
      "cardondelivery": "الدفع بالبطاقة عند الاستلام"
    }
  }
</i18n>
